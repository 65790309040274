import api from "@/api";
import tableActions from "@/store/templates/table/actions";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";

const fetchCall = api.article.fetchAll;

export default {
  ...tableActions,
  async fetch(context) {
    const result = await tableActions.fetch(context, { fetchCall });

    window.Echo.channel("articles").listen(".article.updated", data => {
      const { entities, result } = api.website.normalize(data);
      context.commit("updateItem", { item: result, entities });
    });

    return result;
  },
  async sync(payload, { articleId }) {
    console.warn(
      `Tried to sync article ${articleId}, but this has not been implemented yet.`
    );
  },
  async setSort({ commit, dispatch }, timeframe) {
    commit("setSort", timeframe);
    dispatch(DISPATCH_FETCH);
  }
};
